import About from '@/views/About'
import Athletes from '@/views/Athletes'
import Events from '@/views/Events'
import Home from '@/views/Home'
import Login from '@/views/Login'
import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/athletes',
    name: 'athletes',
    component: Athletes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!firebase.auth().currentUser) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      })
    }
    else {
      next()
    }
  }
  else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (firebase.auth().currentUser) {
      next({
        path: "/events",
        query: {
          redirect: to.fullPath
        }
      })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

export default router