<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    fixed
    clipped
    app
    permanent
  >
    <v-list-item class="px-2">
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-list-item-title>Bogota Sobre Ruedas</v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="{ name: item.name }"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  data: () => ({
    drawer: false,
    items: [
      { title: "Home", icon: "mdi-home-city", name: "home" },
      { title: "Events", icon: "mdi-calendar", name: "events" },
      { title: "Athletes", icon: "mdi-account-group", name: "athletes" },
    ],
    mini: true,
  }),
};
</script>
