import Vue from 'vue'
import App from '@/App.vue'
import firebase from '@/firebaseConfig'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import { store } from '@/store/index'

Vue.config.productionTip = false
let app
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')

  }
})
