<template>
  <v-card class="mx-auto my-12">
    <v-toolbar extended>
      <v-toolbar-title>Events</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-calendar-plus </v-icon>
          </v-btn>
        </template>
        <span>Create a new event</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
        </template>
        <span>Search for an event</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-calendar </v-icon>
          </v-btn>
        </template>
        <span>Calendary view</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <span>Something else?</span>
      </v-tooltip>
    </v-toolbar>

    <Calendar />
  </v-card>
</template>

<script>
import Calendar from "@/components/Calendar";
export default {
  name: "Events",

  components: {
    Calendar,
  },
};
</script>