import firebase from "firebase/app";

var config = {
  apiKey: "AIzaSyASIMIf6tZ3CsETWC7Xz5N1EhclhnIB6BA",
  authDomain: "bsr-dev-4caf6.firebaseapp.com",
  databaseURL: "https://bsr-dev-4caf6-default-rtdb.firebaseio.com",
  projectId: "bsr-dev-4caf6",
  storageBucket: "bsr-dev-4caf6.appspot.com",
  messagingSenderId: "397774227020",
  appId: "1:397774227020:web:5124c2d99fdedc603a96e9",
  measurementId: "G-VPQ2FX6X02"
}

export default firebase.initializeApp(config)
