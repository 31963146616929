import firebase from '@/firebaseConfig'
import 'firebase/firestore'

var db = firebase.firestore()

export default {
  state: {
    events: []
  },
  getters: {
    listEvents(state) {
      return state.events
    },

  },
  mutations: {
    createEvent(state, event) {
      state.events.push(event)
    },
    editEvent(state, event) {
      const index = state.events.findIndex(originalEvt => originalEvt.id === event.id)
      state.events.splice(index, 1, event)
    },
    deleteEvent(state, id) {
      const index = state.events.findIndex(originalEvt => originalEvt.id === id)
      state.events.splice(index, 1)
    },
    retrieveEvents(state, events) {
      state.events = events
    }

  },
  actions: {
    async retrieveEvents(context) {
      try {
        const response = await db.collection('events').get()
        var eventArray = []
        response.forEach(event => eventArray.push(event.data()))


        if (response && eventArray.length > 0) {
          context.commit('retrieveEvents', eventArray)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // async createEvent(context, event) {
    //   const accessToken = localStorage.getItem('access_token')
    //   try {
    //     const response = await axios.post(EVENTS_BASE_PATH, event, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`
    //       }
    //     })
    //     if (response.data.image && response.data.id) {
    //       event.image = response.data.image
    //       event.id = response.data.id
    //     }
    //     context.commit('createEvent', event)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // async updateEvent(context, event) {
    //   const accessToken = localStorage.getItem('access_token')
    //   try {
    //     const response = await axios.put(EVENTS_BASE_PATH + event.id, event, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`
    //       }
    //     })
    //     if (response.data.image) {
    //       event.image = response.data.image
    //     }
    //     context.commit('editEvent', event)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // async deleteEvent(context, id) {
    //   const accessToken = localStorage.getItem('access_token')
    //   try {
    //     await axios.delete(EVENTS_BASE_PATH + id, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`
    //       }
    //     })
    //     context.commit('deleteEvent', id)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
}