<template>
  <div class="home">
    <p>Welcome to BSR's management site, log in to get started!</p>
    <div v-if="!isLoggedIn">
      <v-btn depressed color="primary" @click="$router.push('login')">
        Log in
      </v-btn>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoggedIn: false,
      currentUser: false,
    };
  },
  created() {
    console.log("created");
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUser = firebase.auth().currentUser.email;
    }
  },
};
</script>